import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div>
      <h1>Footer</h1>
      <div className="logos">
        
      </div>
    </div>
    </footer>
  );
}

export default Footer;
